const Chains = new Map([
  // [
  //   "0x539",
  //   {
  //     Network: {
  //       chainId: "0x539",
  //       chainName: "localhost",
  //       rpcUrls: ["http://127.0.0.1:7545"],
  //       nativeCurrency: {
  //         name: "ETH",
  //         symbol: "ETH",
  //         decimals: 18,
  //       },
  //       blockExplorerUrls: ["http://127.0.0.1:7545"],
  //     },
  //     Burn:{
  //       url:"http://127.0.0.1:7545"
  //     },
  //     Contracts: {
  //       Chess: "0xf1f92e6B834CBea685896a6Fd0c3C0C45B059fef",
  //       ChessScore:"0x70CFB6C6879176ed11EAa3e87D84C6719bB3d4e2"
  //     },
  //     Info:""
  //   },
  // ],
  [   "0x4269",
  {
    Network: {
      chainId: "0x4269",
      chainName: "Redstone Holesky",
      rpcUrls: ["https://rpc.holesky.redstone.xyz"],
      nativeCurrency: {
        name: "ETH",
        symbol: "ETH",
        decimals: 18,
      },
      blockExplorerUrls: ["https://explorer.holesky.redstone.xyz"],
    },
    Burn:{
      url:"https://rpc.holesky.redstone.xyz"
    },
    Contracts: {
      Chess: "0x02bad174915cd8fFbC272f73E3260c8Cd3b74C00",
      ChessScore:"0x360ED8222a55FeE2c1074035bDDa2eF378B37b17"
    },
    Info:"https://redstone.xyz/docs/network-info"
  },
  ]

]);

export default Chains;
