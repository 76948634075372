import React, { Component } from "react";
import "./Rooms.css";
import Wallet from "../../components/Wallet/Wallet";
import Chains from "../../libs/Chains";
import ChessBurn from "../../libs/ChessBurn";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.svg";
import BannerLeft from "../../assets/BannerLeft.png";
import BannerRight from "../../assets/BannerRight.png";
import PK from "../../assets/pk.svg";
import { Modal ,message} from "antd";
import Tutorial from "../../components/Tutorial/Tutorial";
import Web3 from "web3";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
class Rooms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showList: false,
      chainId: "",
      currentAddress: "",
      rooms: [],
      myRoomIds: [],
      showTutorial: false,
      roomTab: 0,
      pageNum: 1,
      pageLength: 5,
      showLoadMore: false,
      showLoading:true,
    };
  }

  async componentDidMount() {
    const accounts = await window.ethereum.request({
      method: "eth_accounts",
    });

    var currentAddress = accounts[0] != undefined ? accounts[0] : "";
    var chainId = await window.ethereum.request({ method: "eth_chainId" });
    var currentChainId = Chains.has(chainId) ? chainId : "";
    var myBurnWallet = localStorage.getItem("BurnWallet");
    if (currentAddress == "" || currentChainId == "" || myBurnWallet == null) {
      this.setState({
        showList: true,
      });
    } else {
      var chessBurn = new ChessBurn(chainId);
      chessBurn.addBurnWallet(JSON.parse(myBurnWallet));
      this.setState({
        chessBurn: chessBurn,
        currentAddress: JSON.parse(myBurnWallet).address,
      });

      await this.getRooms(
        this.state.roomTab,
        chessBurn,
        1,
        this.state.pageLength
      );
    }
  }

  async getRooms(roomTab, chessBurn, pageNum, pageLength) {

  
    var rooms = [];
    if (roomTab == 0) {
      rooms = await chessBurn.getRooms(pageNum, pageLength);
    } else {
      rooms = await chessBurn.getRoomsByAddress(
        this.state.currentAddress,
        pageNum,
        pageLength
      );
    }
    var showLoadMore = rooms.length == pageLength;
    this.setState({
      pageNum: pageNum,
      rooms: pageNum == 1 ? rooms : this.state.rooms.concat(rooms),
      showLoadMore: showLoadMore,
  
        showLoading:false,
   
    });
  }

  async loadMoreRooms() {

    var pageNum = parseInt(this.state.pageNum) + 1;
    await this.getRooms(
      this.state.roomTab,
      this.state.chessBurn,
      pageNum,
      this.state.pageLength
    );
  }

  async createNew() {

    const response = await toast.promise(
   
      async()=>{
        var result = await this.state.chessBurn.createNewRoom(
          this.state.currentAddress
        );
    
        this.setState({
          roomTab: 0,
        });

      },
      {
        pending: {render:'Pending',position: "top-center"},
        success: 'Success',
        error: 'Fail'
      },
  );

  await this.getRooms(0, this.state.chessBurn, 1, this.state.pageLength);
 
  }

  async joinNewRoom(roomId) {

    const response = await toast.promise(
      async()=>{
        var result = await this.state.chessBurn.joinRoom(
          roomId,
          this.state.currentAddress
        );
        window.location.href="/room/"+roomId
      },
      {
        pending: {render:'Pending',position: "top-center"},
        success: 'Success',
        error: 'Fail'
      }
  );
  


  }

  getShortAddress(longAddress) {
    let end = longAddress.length;
    let addressShort =
      longAddress.substring(0, 5) + "..." + longAddress.substring(end - 4, end);
    return addressShort;
  }

  async changeRoomTab(roomTab) {

    this.setState({
      rooms:[],
      showLoading:true,
      showLoadMore:false,
      roomTab: roomTab,
    });

    await this.getRooms(
      roomTab,
      this.state.chessBurn,
      1,
      this.state.pageLength
    );
  }

  render() {
    return (
      <div className="RoomContainer">
               <ToastContainer></ToastContainer>
               
        <Modal
          className="TutorialModal"
          title="Tutorial"
          open={this.state.showTutorial}
          footer={null}
          maskClosable={true}
          onCancel={() => {
            this.setState({
              showTutorial: false,
            });
          }}
        >
          <Tutorial></Tutorial>
        </Modal>
        <div className="Banner">
          <div className="BannerLeft">
            <img src={BannerLeft} width="100%" />
          </div>
          <div className="BannerCenter">
            <div className="BannerLogo">
              <img src={logo} width="100%" />
            </div>
            <div className="BannerSologan">Multiplayer Onchain Chess</div>
            <div className="BuiltOn" target="_blank">
            Chain Chess is a fully onchain strategy game. It uses the <a href="https://github.com/HelloRickey/MultiplayerOnchainGame" target="_blank">Multiplayer Onchain Game Contract</a>. Built by <a href="https://twitter.com/Web3Rickey" target="_blank">Rickey.</a>  Deployed on <a href="https://redstone.xyz/docs/network-info" target="_blank">Redstone Holesky Network</a> .
        
            </div>
            <div className="BannerBtns">
              <div
                className="TutorialBtn"
                onClick={() => {
                  this.setState({
                    showTutorial: true,
                  });
                }}
              >
                HOW TO PLAY
              </div>
              <a href="https://twitter.com/chainchess_xyz" target="_blank" className="TwitterBtn">TWITTER</a>
            </div>
          </div>
          <div className="BannerRight">
            <img src={BannerRight} width="100%" />
          </div>
        </div>
        <Wallet
        // updateWallet={this.updateWallet.bind(this)}
        // updateChain={this.updateChain.bind(this)}
        ></Wallet>
        <div hidden={this.state.showList} className="Rooms">
          <div className="RoomHead">
            <div className="RoomHeadTitle">ROOMS</div>
            <div className="RoomTab">
              <div
                className={
                  this.state.roomTab ? "RoomTabNormal" : "RoomTabSelect"
                }
                onClick={this.changeRoomTab.bind(this, 0)}
              >
                ALL
              </div>
              <div
                className={
                  this.state.roomTab ? "RoomTabSelect" : "RoomTabNormal"
                }
                onClick={this.changeRoomTab.bind(this, 1)}
              >
                MY
              </div>
            </div>
            <div className="CreateRoom" onClick={this.createNew.bind(this)}>
              CREATE
            </div>
          </div>

          {this.state.rooms.length != 0 ? (
            <ul className="RoomList">
              {this.state.rooms.map((item, index) => (
                <li key={index}>
                  <div className="RoomInfo">
                    <div className="RoomId">
                      <div className="RoomIdText">ROOM</div>
                      <div className="RoomIdNumber">#{item.roomId}</div>
                    </div>
                    <div className="RoomPlayers">
                      <div className="RoomPlayer1">
                        {this.getShortAddress(item.roomInfo[3][0])}
                      </div>

                      {item.roomInfo[3][1] !=
                      "0x0000000000000000000000000000000000000000" ? (
                        <div className="RoomPlayer2">
                          <img src={PK} className="RoomPK" />
                          <div className="RoomPlayer1">
                            {this.getShortAddress(item.roomInfo[3][1])}
                          </div>
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  </div>

                  <div className="RoomButtons">
                    {item.roomInfo[3].includes(
                      Web3.utils.toChecksumAddress(this.state.currentAddress)
                    ) ? (
                      <div></div>
                    ) : item.roomInfo[3][1] ==
                      "0x0000000000000000000000000000000000000000" ? (
                      <div
                        className="JoinRoomButton"
                        onClick={this.joinNewRoom.bind(this, item.roomId)}
                      >
                        JOIN
                      </div>
                    ) : (
                      <div></div>
                    )}

                    <Link to={"/room/" + item.roomId} target="_blank" className="ViewRoomButton">
                    VIEW
                    </Link>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <div className="NoRooms">
              {this.state.showLoading?(
                  <div>Loading</div>
              ):(
                  <div>No Room</div>
              )}
            </div>
          )}

          <div
            hidden={!this.state.showLoadMore}
            className="LoadMore"
            onClick={this.loadMoreRooms.bind(this)}
          >
            LOAD MORE
          </div>
        </div>
      </div>
    );
  }
}
export default Rooms;
