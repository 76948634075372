import Player1King from "../../assets/player1/king.png";
import Player1Knight from "../../assets/player1/knight.png";
import Player1Magician from "../../assets/player1/magician.png";
import Player1Guard from "../../assets/player1/guard.png";
import Player1Assassin from "../../assets/player1/assassin.png";
import Player1Castle from "../../assets/player1/castle.png"

import Player2King from "../../assets/player2/king.png";
import Player2Knight from "../../assets/player2/knight.png";
import Player2Magician from "../../assets/player2/magician.png";
import Player2Guard from "../../assets/player2/guard.png";
import Player2Assassin from "../../assets/player2/assassin.png";
import Player2Castle from "../../assets/player2/castle.png"

const ChessImages = new Map([
  [1, Player1Assassin],
  [2, Player1Guard],
  [3, Player1Magician],
  [4, Player1Knight],
  [5, Player1King],
  [6, Player1Castle],

  [11, Player2Assassin],
  [12, Player2Guard],
  [13, Player2Magician],
  [14, Player2Knight],
  [15, Player2King],
  [16, Player2Castle],
]);

export default ChessImages;
