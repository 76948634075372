import Web3 from "web3";
import ChessABI from "./abi/Chess.json";
import ChessScoreABI from "./abi/ChessScore.json";
import Chains from "./Chains";
class ChessBurn {
  constructor(chain) {

    this.chain = chain;
    this.web3 = new Web3(Chains.get(chain).Burn.url);
    this.Chess = new this.web3.eth.Contract(
      ChessABI,
      Chains.get(chain).Contracts.Chess
    );
    this.ChessScore = new this.web3.eth.Contract(
      ChessScoreABI,
      Chains.get(chain).Contracts.ChessScore
    );

  }

  addBurnWallet(burnWallet){
    // this.burnWallet=burnWallet;
    this.web3.eth.accounts.wallet.add(burnWallet.privateKey);
  }

  async getChessScore(_sender){
    var score=await this.ChessScore.methods.getGameHistory(_sender).call();
    return score;
  }

  async getRoomCount() {
    var result = await this.Chess.methods.getRoomCount().call();
    return result.toString();
  }




  async getRooms(page,pageLength){


  

    var count = await this.Chess.methods.getRoomCount().call();

    var roomIds= this.getArray(page, pageLength, count);
    var rooms=[]
    for(var i of roomIds){
      var room=await this.getRoomInfoById(i);
      rooms.push({
        roomId:i,
        roomInfo:room
      })
    }
    
    return rooms;

  }

  async getRoomInfoById(roomId){
    var room=await this.Chess.methods.getRoomInfo(roomId).call();
    return room;
  }

  async getRoomsByAddress(from,page,pageLength){
    var allRoomIds = await this.Chess.methods.getRoomIds(from).call();
  
    var roomIds= this.getIdsArray(page, pageLength, allRoomIds);
  
    var rooms=[]
    for(var i of roomIds){
    
      var room=await this.getRoomInfoById(i);
      rooms.push({
        roomId:i,
        roomInfo:room
      })
    }
  
    return rooms;
  }

  async getRoomLastTime(roomId){
    var room=await this.Chess.methods.getPlayerLastTime(roomId).call();
    return room;
  }

  async applyToWin(_sender,_roomId,_playerId){
    const gasPrice = await this.web3.eth.getGasPrice();
    const gas = await this.Chess.methods.applyWin(_roomId,_playerId).estimateGas({ from: _sender });
    var result = await this.Chess.methods
      .applyWin(_roomId,_playerId)
      .send({ from: _sender ,gasPrice,gas})
      .on("transactionHash", function (hash) {
        console.info(hash);
      })
      .on("receipt", function (receipt) {
        console.log("receipt", receipt);
        return receipt;
      });
  }


   getIdsArray(page, pageLength, roomIds) {
    var arr = [];
    var count = roomIds.length;
    if (count <= pageLength) {
      var start = count - 1;
      var end = 0;
      for (var i = start; i >= end; i--) {
        arr.push(roomIds[i]);
      }
    } else {
      var start = count - (page - 1) * pageLength - 1;
      var end = start - pageLength;
      for (var i = start; i > end; i--) {
        if (i >= 0) {
          arr.push(roomIds[i]);
        }
      }
    }

    return arr;
  }
  

  getArray(page, pageLength, count) {
    var arr = [];
  
    if (count <= pageLength) {
      var start = count - 1;
      var end = 0;
      for (var i = start; i >= end; i--) {
        arr.push(i);
      }
    } else {
      var start = count - (page - 1) * pageLength - 1;
      var end = start - pageLength;
      for (var i = start; i > end; i--) {
        if (i >= 0) {
          arr.push(i);
        }
      }
    }
  
    return arr;
  }

  async getMyRoom(_from) {
    var result = await this.Chess.methods.getRoomIds(_from).call();
    return result;
  }

  async createNewRoom(_sender) {

    const gasPrice = await this.web3.eth.getGasPrice();
    const gas = await this.Chess.methods.createRoom().estimateGas({ from: _sender, value: 0 });
    var result = await this.Chess.methods
      .createRoom()
      .send({ from: _sender, value: 0 ,gasPrice,gas})
    
      .on("transactionHash", function (hash) {
        console.info(hash);
      })
      .on("receipt", function (receipt) {
        console.log("receipt", receipt);
        return receipt;
      });

    return result.events.CreateRoomEvent.returnValues;
  }



  async joinRoom(_roomId, _sender) {
    const gasPrice = await this.web3.eth.getGasPrice();
    const gas = await this.Chess.methods.joinRoom(_roomId).estimateGas({ from: _sender });
    var result = await this.Chess.methods
      .joinRoom(_roomId)
      .send({ from: _sender ,gasPrice,gas})
      .on("transactionHash", function (hash) {
        console.info(hash);
      })
      .on("receipt", function (receipt) {
        console.log("receipt", receipt);
        return receipt;
      });
  }

  async isJoinRoom(_roomId, _sender) {
    var result = await this.Chess.methods
      .hasMember(_roomId, _sender)
      .call();
    return result;
  }

  async getRoomUserId(_roomId, _sender) {
    var result = await this.Chess.methods
      .getChannelUserIds(_roomId, _sender)
      .call();
    return result;
  }

  async getRoomChessBorad(_roomId) {
    var result = await this.Chess.methods.getChessBorad(_roomId).call();
    return result;
  }

  async getRoomPlayerChess(_roomId, _playerId) {
    var result = await this.Chess.methods
      .getPlayerChess(_roomId, _playerId)
      .call();
    return result;
  }

  async getRoomRound(_roomId) {
    var result = await this.Chess.methods.getRound(_roomId).call();
    return result;
  }

  async getRoomPlayerCount(_roomId) {
    var result = await this.Chess.methods.getChannelUserCount(_roomId).call();
    return result;
  }

  async getRoomGameState(_roomId) {
    var result = await this.Chess.methods.getGameState(_roomId).call();
    return result;
  }

  async chessMove(
    _sender,
    _roomId,
    _playerId,
    _oppoentId,
    _chessId,
    _currentPosition,
    _nextPosition
  ) {

    var message = this.web3.eth.abi.encodeParameters(
      ["uint256", "uint256", "uint256", "uint256"],
      [_playerId, _chessId, _currentPosition, _nextPosition]
    );
    const gasPrice = await this.web3.eth.getGasPrice();
    const gas = await this.Chess.methods.sendMessage(_roomId, [_oppoentId], message, []).estimateGas({ from: _sender });
    var result = await this.Chess.methods
      .sendMessage(_roomId, [_oppoentId], message, [])
      .send({ from: _sender ,gasPrice,gas})
      .on("transactionHash", function (hash) {
        console.info(hash);
      })
      .on("receipt", function (receipt) {
        console.log("receipt", receipt);
        return receipt;
      });
    return result;
  }

  // async getTotalSupply(){
  //   let counts = await  this.contract.methods.totalSupply().call();
  //   return counts;
  // }

  // async mint(_characterIdAndAmount, _sender, _value) {
  //   var result = await this.EtherWarHeroContract.methods
  //     .mint(_characterIdAndAmount)
  //     .send({ from: _sender, value: _value })
  //     .on("transactionHash", function (hash) {
  //       console.info(hash);
  //       // return hash;
  //     })
  //     .on("receipt", function (receipt) {
  //       return receipt;
  //     });

  //   return result;
  // }

  // async whiteListBalance(_sender){
  //   var result=await this.EtherWarHeroContract.methods.WhitelistMapping(_sender).call();
  //   return result;
  // }

  // async getHeroesByAddress(from,pageNum,pageLength) {
  //   var start= (pageNum-1)*pageLength;
  //   var max=(pageNum-1)*pageLength+pageLength;
  //   let token_URIs = [];
  //   var heroes=[];
  //   for(var i=0;i<HeroesJSON.length;i++){
  //     heroes= heroes.concat(HeroesJSON[i].heroList)
  //   }
  //   let counts = await this.EtherWarHeroContract.methods.balanceOf(from).call();
  //   var totalPageNum;
  //   if(counts%pageLength==0){
  //     totalPageNum=counts/pageLength;
  //   }else{
  //     totalPageNum=counts/pageLength+1;
  //   }
  //   if(pageNum>totalPageNum){
  //     return []
  //   }
  //   if(max>counts){
  //     max=counts;
  //   }
  //   for (let i = start; i < max; i++) {
  //     let token_id = await this.EtherWarHeroContract.methods
  //       .tokenOfOwnerByIndex(from, i)
  //       .call();

  //     token_URIs.push(await this.getHeroById(token_id));
  //   }
  //   return token_URIs;
  // }

  // async getHeroById(_id){
  //   let hero= await this.EtherWarHeroContract.methods.tokenURI(_id).call();
  //   var heroMetadata=JSON.parse(atob(hero.split(",")[1]));

  //   var spirit=await this.EtherWarGameContract.methods.HeroesSpirit(_id).call();

  //   if(spirit!=0){
  //     var blockNumber= parseInt(await this.web3.eth.getBlockNumber());

  //     var perGameBlockCount = (24 * 3600) / parseInt(this.chain.blockSpeed)  / parseInt(this.chain.gamesPerDay) ;

  //     console.log("spirit",spirit);
  //     console.log("blockNumber",blockNumber);
  //     console.log("blockNumber-spirit",blockNumber-spirit);
  //     console.log("perGameBlockCount",perGameBlockCount);

  //     spirit=parseInt((blockNumber-spirit)/perGameBlockCount);

  //     if(spirit>3){
  //       spirit=3;
  //     }
  //   }else{
  //     spirit=3;
  //   }

  //   heroMetadata.attributes.push({
  //     "trait_type":"spirit",
  //     "value":spirit
  //   })

  //   var heroImage;
  //   for(var y=0;y<HeroesJSON.length;y++){
  //     for(var k=0;k<HeroesJSON[y].heroList.length;k++){
  //       if(heroMetadata.name==HeroesJSON[y].heroList[k].heroName){
  //         heroImage=HeroesJSON[y].heroList[k].heroImage;
  //       }
  //     }
  //   }

  //   return {
  //     tokenId:_id,
  //     tokenURI:heroMetadata,
  //     tokenImage:heroImage,
  //   }
  // }

  // //EtherWarGame
  // async getTeamsCount(_sender){
  //   var teamsCount=await this.EtherWarGameContract.methods.AddressTeamCount(_sender).call();
  //   return teamsCount;
  // }

  // async getMyTeams(_sender){
  //   var teamCount=await this.getTeamsCount(_sender);

  //   var teams=[];
  //   for(var i=teamCount-1;i>=0;i--){

  //     console.log(i);

  //     var teamId=await this.EtherWarGameContract.methods.AddressIndexTeamMapping(_sender,i).call();

  //     console.log("teamId",teamId)

  //     var team=await this.getTeamById(teamId);

  //     teams.push({
  //       id:teamId,
  //       name:team[0],
  //       heroes:team[1],
  //       owner:team[2],
  //       active:team[3]
  //     });
  //   }
  //   return teams;
  // }

  // async getTeamById(_id){
  //   var team=await this.EtherWarGameContract.methods.getTeamById(_id).call();
  //   return team;
  // }

  // async createNewTeam(_sender,_name,_teams){

  //   var isApproved=await this.EtherWarHeroContract.methods.isApprovedForAll(_sender,this.chain.EtherWarGame).call();
  //   console.log(isApproved);
  //   if(!isApproved){
  //     var approved=await this.EtherWarHeroContract.methods.setApprovalForAll(this.chain.EtherWarGame,true).send({ from: _sender});
  //   }

  //   var result = await this.EtherWarGameContract.methods
  //   .createTeam(_name,_teams)
  //   .send({ from: _sender})
  //   .on("transactionHash", function (hash) {
  //     console.info(hash);
  //   })
  //   .on("receipt", function (receipt) {
  //     return receipt;
  //   });
  //   return result;

  // }

  // async EditTeam(_sender,_teamId,_name,_teams){

  //   var result = await this.EtherWarGameContract.methods
  //   .teamManage(_teamId,_name,_teams)
  //   .send({ from: _sender})
  //   .on("transactionHash", function (hash) {
  //     console.info(hash);
  //   })
  //   .on("receipt", function (receipt) {
  //     return receipt;
  //   });
  //   return result;

  // }

  // async startFight(_sender,_teamId){
  //   var result = await this.EtherWarGameContract.methods
  //   .playGame(_teamId)
  //   .send({ from: _sender})
  //   .on("transactionHash", function (hash) {
  //     console.info(hash);
  //   })
  //   .on("receipt", function (receipt) {
  //     return receipt;
  //   });
  //   return result;
  // }

  // async getPoints(_sender){
  //   var points=await this.EtherWarTokenContract.methods.balanceOf(_sender).call();

  //   return  Web3.utils.fromWei(points);
  // }

  // async getCost(){
  //   var cost=await this.EtherWarHeroContract.methods.cost().call();
  //   return Web3.utils.fromWei(cost);
  // }
}

export default ChessBurn;
