
import Chess from "./pages/Chess/Chess"
import Rooms from "./pages/Rooms/Rooms"
import Tutorial from "./components/Tutorial/Tutorial";
import BurnerWallet from "./components/BurnerWallet/BurnerWallet"
const routes = [
  {
    path: "/",
    element: <Rooms/>,
  }, {
    path: "/Room/:id",
    element: <Chess/>,
  },{
    path:"/Tutorial",
    element:<Tutorial/>
  },{
    path:"/BurnerWallet",
    element:<BurnerWallet/>
  }
  
];
export default routes;