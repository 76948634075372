import React, { Component } from "react";
import { message, Modal } from "antd";
import Chains from "../../libs/Chains";
import win from "../../assets/winface.svg";
import lose from "../../assets/loseface.svg";
import draw from "../../assets/drawface.svg";
import ChessBurn from "../../libs/ChessBurn";
import { Menu, MenuItem } from "@szhsin/react-menu";
import arrow from "../../assets/arrow.svg";
import Web3 from "web3";
import EthCrypto from "eth-crypto";
import "./Wallet.css";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
class Wallet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentAddress: "",
      currentChainId: "",
      score: [],
      showNewBurnWallet: false,
      showBurnWallet: true,
      burnWalletTab: 0,
      newBurnWallet: "",
      importPrivateKey: "",
      deposit: 0,
      showLoading: false,
      myBurnWallet: {
        address: "",
        privateKey: "",
        balance: "",
      },
    };
  }
  async componentDidMount() {
    const { ethereum } = window;

    var that = this;

    if (ethereum && ethereum.isMetaMask) {
      var currentAddress = await that.initAccount();
      var currentChainId = await that.initNetwork();
      window.ethereum.on("accountsChanged", async function () {
        //await that.initAccount();

        window.location.reload();
      });
      window.ethereum.on("chainChanged", async function () {
        //await that.initNetwork();

        window.location.reload();
      });
      if (currentAddress != "" && currentChainId != "") {
        var burnWallet = localStorage.getItem("BurnWallet");
        if (burnWallet != null) {
          var web3 = new Web3(window.ethereum);
          var burnWalletJson = JSON.parse(burnWallet);
          var balance = await web3.eth.getBalance(burnWalletJson.address);
          burnWalletJson.balance = balance;

          var chessBurn = new ChessBurn(currentChainId);
          var score = await chessBurn.getChessScore(burnWalletJson.address);

          this.setState({
            score: score,
            showBurnWallet: false,
            myBurnWallet: burnWalletJson,
          });
        } else {
          this.setState({
            showBurnWallet: true,
          });
        }
      }
    }
  }

  initAccount = async () => {
    const accounts = await window.ethereum.request({
      method: "eth_accounts",
    });

    var currentAddress = accounts[0] != undefined ? accounts[0] : "";

    this.setState({
      currentAddress: currentAddress,
    });

    return currentAddress;
  };

  initNetwork = async () => {
    var chainId = await window.ethereum.request({ method: "eth_chainId" });
    var currentChainId = Chains.has(chainId) ? chainId : "";
    this.setState({
      currentChainId: currentChainId,
    });

    return currentChainId;
  };

  changeNetwork = async (chainId) => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: chainId }],
      });
      this.setState({
        connectWalletModalIsOpen: false,
      });
    } catch (switchError) {
      if (switchError.code === 4902) {
        try {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [Chains.get(chainId).Network],
          });

          this.initNetwork();
        } catch (addError) {
          console.log(addError);
        }
      }
    }
  };

  handleConnect = async () => {
    if (typeof window.ethereum !== "undefined") {
      await window.ethereum.request({
        method: "eth_requestAccounts",
      });
    } else {
      message.warning("Please install metamask and switch to Network.", 3);
    }
  };

  get address() {
    let end = this.state.currentAddress.length;
    let addressShort =
      this.state.currentAddress.substring(0, 5) +
      "..." +
      this.state.currentAddress.substring(end - 4, end);
    return addressShort;
  }

  getShortAddress(longAddress) {
    let end = longAddress.length;
    let addressShort =
      longAddress.substring(0, 5) + "..." + longAddress.substring(end - 4, end);
    return addressShort;
  }

  closeModal = () => {
    this.setState({
      showNewBurnWallet: false,
    });
  };

  addWallet = () => {
    const newBurnWallet = EthCrypto.createIdentity();
    this.setState({
      newBurnWallet: newBurnWallet,
      showNewBurnWallet: true,
    });
  };

  changeBurnTab = (currentTab) => {
    this.setState({
      burnWalletTab: currentTab,
    });
  };

  generateBurnWallet = () => {
    const newBurnWallet = EthCrypto.createIdentity();
    this.setState({
      newBurnWallet: newBurnWallet,
    });
    console.log("newBurnWallet", newBurnWallet);
  };

  removeBurnWallet = async () => {
    localStorage.removeItem("BurnWallet");
    window.location.reload();
  };

  createNewBurnWallet = async () => {
    // const toastId = toast(
    //   <div>pending</div>,
    //   // <div className="creating">
    //   //   <img src={loading} width={48}></img>
    //   //   <p>Pending</p>
    //   // </div>,
    //   {
    //     closeOnClick: false,
    //     autoClose: false,
    //     position: "top-center",
    //   }
    // );

    const response = await toast.promise(
      async () => {
        var newBurnWallet;
        if (this.state.burnWalletTab == 0) {
          newBurnWallet = this.state.newBurnWallet;
        } else {
          var web3 = new Web3(window.ethereum);
          var account = web3.eth.accounts.privateKeyToAccount(
            this.state.importPrivateKey
          );
          newBurnWallet = {
            address: account.address,
            privateKey: this.state.importPrivateKey,
          };
        }

        if (this.state.deposit == 0 || this.state.deposit == "") {
          localStorage.setItem("BurnWallet", JSON.stringify(newBurnWallet));
          this.setState({
            showNewBurnWallet: false,
          });



          window.location.reload();
        } else {
          this.setState({
            showNewBurnWallet: false,
        
          });
          var web3 = new Web3(window.ethereum);


          var result = await web3.eth.sendTransaction({
            from: this.state.currentAddress,
            to: newBurnWallet.address,
            value: Web3.utils.toWei(this.state.deposit, "ether"),
          });
          localStorage.setItem("BurnWallet", JSON.stringify(newBurnWallet));
          this.setState({
            showNewBurnWallet: false,
       
          });



          window.location.reload();

     

          console.log("result", result);
        }

        // this.writeToFile(newBurnWallet)

      
      },
      {
        pending: { render: "pending", position: "top-center" },
        success: "Success",
        error: "Fail",
      }
    );


  };

 writeToFile(burnerWallet){
    const fileData = JSON.stringify(burnerWallet);
    const blob = new Blob([fileData], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.download = burnerWallet.address+'.json';
    link.href = url;
    link.click();
  };

  render() {
    return (
      <div>
   <ToastContainer></ToastContainer>
        <Modal
          title="Burner Wallet"
          open={this.state.showNewBurnWallet}
          destroyOnClose={true}
          onCancel={this.closeModal}
          footer={null}
        >
          {/* <div className="BurnWalletTab">
            <div
              className={
                this.state.burnWalletTab == 0
                  ? "BurnWalletTabSelect"
                  : "BurnWalletTabNormal"
              }
              onClick={this.changeBurnTab.bind(this, 0)}
            >
              New
            </div>
            <div
              className={
                this.state.burnWalletTab == 1
                  ? "BurnWalletTabSelect"
                  : "BurnWalletTabNormal"
              }
              onClick={this.changeBurnTab.bind(this, 1)}
            >
              Import
            </div>
          </div> */}

          {this.state.burnWalletTab == 0 ? (
            <div className="BurnWalletNew">
              <div className="BurnWalletNewInfo">
                <div>
                  <span>Address</span>
                  <br />
                  {this.state.newBurnWallet.address}
                </div>
                <div>
                  <span>Private Key</span>
                  <br />
                  {this.state.newBurnWallet.privateKey}
                </div>
              </div>
              <div
                className="BurnWalletGenerate"
                onClick={this.generateBurnWallet.bind(this)}
              >
                Regenerate
              </div>
            </div>
          ) : (
            <input
              className="BurnWalletInput"
              placeholder="Private Key"
              onChange={(e) => {
                this.setState({
                  importPrivateKey: e.target.value,
                });
              }}
            />
          )}

          <input
            type="number"
            className="BurnWalletInput"
            placeholder="Deposit Amount(Optional)"
            onChange={(e) => {
              this.setState({
                deposit: e.target.value,
              });
            }}
          />
          <div className="BurnerWallerTip">
            Burner Wallet is a temporary wallet used for games, all your gas fees in the game will be paid by burner wallet, please back it
            up yourself.
          </div>
          <div
            className="BurnWalletCreate"
            onClick={this.createNewBurnWallet.bind(this)}
          >
            CREATE
          </div>

          <div hidden={!this.state.showLoading} className="BurnWalletLoading">
            Loading
          </div>
        </Modal>

        <div
          className={
            this.state.currentAddress != "" && this.state.currentChainId != ""
              ? "WalletPannelConnect"
              : "WalletPannel"
          }
        >
          {this.state.currentAddress == "" ||
          this.state.currentChainId == "" ? (
            <div className="WalletTitle">Connect Wallet</div>
          ) : (
            <div></div>
          )}

          {this.state.currentAddress == "" ? (
            <div className="WalletBtn" onClick={this.handleConnect}>
              {/* <img src={WalletIcon} width={24}></img> &nbsp; */}
              <div>CONNECT</div>
            </div>
          ) : (
            <div>
              <div className="WalletAddressConnect">
                <div>Network</div>

                <div>
                  {Chains.has(this.state.currentChainId)
                    ? Chains.get(this.state.currentChainId).Network.chainName
                    : ""}
                </div>
              </div>
              <div className="WalletAddressConnect">
                {/* <img src={WalletIcon} width={32}></img> */}
                <div>Main Wallet</div>
                <div>{this.getShortAddress(this.state.currentAddress)}</div>
              </div>

              <div className="WalletAddressConnect">
                <div>Burner Wallet</div>
                {this.state.showBurnWallet ? (
                  <div>
                    <div
                      onClick={this.addWallet.bind(this)}
                      className="AddBurnWalletBtn"
                    >
                      Add Burner Wallet
                    </div>
                  </div>
                ) : (
                  <div>
                    <div>
                      <Menu
                        menuClassName="myBurnWalletMenu"
                        menuButton={
                          <div className="BurnWalletAddress">
                            <div>
                              {this.getShortAddress(
                                this.state.myBurnWallet.address
                              )}
                              <br />
                              Balance:
                              {Web3.utils.fromWei(
                                this.state.myBurnWallet.balance
                              )}
                            </div>
                            <div>
                              <img src={arrow} width={18} />
                            </div>
                          </div>
                        }
                      >
                        <MenuItem className="myBurnWalletMenuItem">
                          <CopyToClipboard
                            text={this.state.myBurnWallet.address}
                          >
                            <span>Copy Address</span>
                          </CopyToClipboard>
                        </MenuItem>
                        <MenuItem
                          className="myBurnWalletMenuItem"
                          onClick={this.addWallet.bind(this)}
                        >
                          Change Wallet
                        </MenuItem>
                        <MenuItem
                          className="myBurnWalletMenuItem"
                          onClick={this.removeBurnWallet.bind(this)}
                        >
                          Remove
                        </MenuItem>
                      </Menu>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}

          <ul
            hidden={
              this.state.currentChainId != "" || this.state.currentAddress == ""
            }
            className="NetworkList"
          >
            <div className="NetworkTitle">Select a Network</div>
            {Array.from(Chains).map((item, index) => (
              <li key={index}>
                <div
                  className="NetworkButton"
                  onClick={this.changeNetwork.bind(this, item[0])}
                >
                  {" "}
                  {item[1].Network.chainName}{" "}
                </div>
                <a href={item[1].Info} className="NetworkInfo" target="_blank">
                  {item[1].Network.chainName}&nbsp;Network Info
                </a>
              </li>
            ))}
          </ul>

          {this.state.currentChainId != "" &&
          this.state.currentAddress != "" &&
          this.state.myBurnWallet.address != "" ? (
            <div className="GameScoreContainer">
              <div className="GameScoreTitle">Score</div>
              <ul className="GameScore">
                <li>
                  <img src={win} width={18} /> {this.state.score[1]}
                </li>
                <li>
                  <img src={lose} width={18} /> {this.state.score[0]}
                </li>
                <li>
                  <img src={draw} width={18} /> {this.state.score[2]}
                </li>
              </ul>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    );
  }
}
export default Wallet;
