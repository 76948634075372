import React, { Component } from "react";
import "./Chess.css";
import ChessImages from "./ChessImages";
import withRouter from "../../withRouter";
import ChessBurn from "../../libs/ChessBurn";
import Chains from "../../libs/Chains";
import Logo from "../../assets/logo.svg";
import Wallet from "../../components/Wallet/Wallet";
import { Modal, message } from "antd";
import Tutorial from "../../components/Tutorial/Tutorial";
import Web3 from "web3";
import WinnerIcon from "../../assets/winnericon.svg";
import { ToastContainer, toast } from "react-toastify";
import { CopyToClipboard } from "react-copy-to-clipboard";
class Chess extends Component {
  constructor(props) {
    super(props);

    this.state = {
      roomId: 0,
      playerId: 0,
      playerChess: [
        [5, 4, 3, 2, 1],
        [15, 14, 13, 12, 11],
      ],
      selectedPostion: 100,
      chessBoard: [
        1, 0, 0, 0, 4, 0, 2, 5, 3, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 13, 15, 12, 0, 14, 0, 0, 0, 11,
      ],
      isSelect: false,
      movePositions: [],
      moveAble: 100,
      castle: [2, 32],
      showBoard: false,
      isJoin: false,
      round: 0,

      showTutorial: false,
      players: ["", ""],

      isUpdate: true,
      showApplyWin: false,
    };
  }

  async componentDidMount() {
    var roomId = this.props.params.id;
    const accounts = await window.ethereum.request({
      method: "eth_accounts",
    });

    var currentAddress = accounts[0] != undefined ? accounts[0] : "";
    var chainId = await window.ethereum.request({ method: "eth_chainId" });
    var currentChainId = Chains.has(chainId) ? chainId : "";
    var myBurnWallet = localStorage.getItem("BurnWallet");

    var chessBurn = new ChessBurn(chainId);
    chessBurn.addBurnWallet(JSON.parse(myBurnWallet));

    if (currentAddress == "" || currentChainId == "" || myBurnWallet == null) {
      this.setState({
        showBoard: true,
      });
    } else {
      await this.getChessData(
        chessBurn,
        roomId,
        JSON.parse(myBurnWallet).address
      );

      var that = this;
      setInterval(async () => {
        if (that.state.isUpdate) {
          await that.getChessData(
            chessBurn,
            roomId,
            JSON.parse(myBurnWallet).address
          );
        }
      }, 12000);
    }
  }

  async getChessData(chessBurn, roomId, currentAddress) {
    var roomInfo = await chessBurn.getRoomInfoById(roomId);

    // console.log("roomInfo", roomInfo);

    var gameState = roomInfo[0];
    var round = roomInfo[1];
    var players = roomInfo[3];

    var winner = roomInfo[2];

    // console.log("players", players[0]);

    var currentPlayerId = players.indexOf(
      Web3.utils.toChecksumAddress(currentAddress)
    );

    //show apply win
    var lastTime = await chessBurn.getRoomLastTime(roomId);
    var oppoentPlayerId;
    var showApplyWin = false;
    if (currentPlayerId == 1) {
      oppoentPlayerId = 0;
    } else {
      oppoentPlayerId = 1;
    }

    var now = new Date();
    var currentTime = Math.floor(now.getTime() / 1000);

    if (
      currentTime - lastTime[1][oppoentPlayerId] > lastTime[0] &&
      gameState == 1
    ) {
      showApplyWin = true;
    }

    console.log(currentTime);
    console.log(lastTime);

    var chessBorad = roomInfo[4];
    var playerChess = roomInfo[5][0];
    var opponentChess = roomInfo[5][1];

    var isJoin = roomInfo[3].includes(
      Web3.utils.toChecksumAddress(currentAddress)
    );

    this.setState({
      roomId: roomId,
      showApplyWin: showApplyWin,
      players: players,
      isJoin: isJoin,
      round: round,
      winner: winner,
      chessBurn: chessBurn,
      currentAddress: currentAddress,
      chessBoard:
        chessBorad.length != 0
          ? chessBorad.map((str) => parseInt(str))
          : this.state.chessBoard,
      playerId: currentPlayerId,
      gameState: parseInt(gameState),
      playerChess: [
        playerChess.map((str) => parseInt(str)),
        opponentChess.map((str) => parseInt(str)),
      ],
    });
  }

  selectChess(currentPosition) {
    console.log("isJoin", this.state.isJoin);
    console.log("round", this.state.round);
    console.log("this.state.playerId", this.state.playerId);
    console.log("this.state.players[1]", this.state.players[1]);
    console.log("this.state.gameState", this.state.gameState);
    //是否可以move chess
    if (
      this.state.isJoin == true &&
      this.state.round == this.state.playerId &&
      this.state.players[1] != "0x0000000000000000000000000000000000000000" &&
      this.state.gameState != 2
    ) {
      //是否中了一个棋子
      if (this.state.isSelect) {
        //判断移动的位置是否是一个空位置
        if (this.state.chessBoard[currentPosition] == 0) {
          //判断移动的位置是否正确
          if (this.state.movePositions.includes(currentPosition)) {
            var chessBoard = this.state.chessBoard;
            var chessId = chessBoard[this.state.selectedPostion];
            chessBoard[currentPosition] =
              chessBoard[this.state.selectedPostion];
            chessBoard[this.state.selectedPostion] = 0;
            this.setState({
              chessBoard: chessBoard,
              isSelect: false,
              moveAble: 100,
              selectedPostion: 100,
              movePositions: [],
            });
            this.checkWinner(currentPosition);

            console.log(
              "this.state.selectedPostion",
              this.state.selectedPostion
            );

            this.moveEvent(
              chessId,
              this.state.selectedPostion,
              currentPosition
            );

            //   if (this.state.playerId == 0 && currentPosition == 27) {
            //     alert("player 1 win");
            //   } else if (this.state.playerId == 1 && currentPosition == 2) {
            //     alert("player 2 win");
            //   }
          }
        }
        //如果移动的位置不是空白位置
        else if (this.state.chessBoard[currentPosition] != 0) {
          if (this.state.movePositions.includes(currentPosition)) {
            //判断切换棋子
            if (
              this.state.playerChess[this.state.playerId].includes(
                this.state.chessBoard[currentPosition]
              )
            ) {
              var movePositions = this.getMovePostions(currentPosition);
              this.setState({
                selectedPostion: currentPosition,
                movePositions: movePositions,
                isSelect: true,
              });
            } else {
              var chessBoard = this.state.chessBoard;

              var opponentChessId = chessBoard[currentPosition];
              var playerChessId = chessBoard[this.state.selectedPostion];
              var playerChess = this.state.playerChess;
              if (this.state.playerId == 0) {
                if (playerChessId == 5 && opponentChessId == 11) {
                  console.log("kill king");

                  chessBoard[this.state.selectedPostion] = 0;
                  chessBoard[currentPosition] = opponentChessId;

                  playerChess[0] = playerChess[0].filter(
                    (item) => item !== playerChessId
                  );
                  this.moveUpdate(chessBoard, playerChess);
                } else if (playerChessId == 1 && opponentChessId == 15) {
                  chessBoard[this.state.selectedPostion] = 0;
                  chessBoard[currentPosition] = playerChessId;

                  playerChess[1] = playerChess[1].filter(
                    (item) => item !== opponentChessId
                  );
                  this.moveUpdate(chessBoard, playerChess);
                } else {
                  if (playerChessId > opponentChessId - 10) {
                    chessBoard[this.state.selectedPostion] = 0;
                    chessBoard[currentPosition] = playerChessId;
                    playerChess[1] = playerChess[1].filter(
                      (item) => item !== opponentChessId
                    );
                    this.moveUpdate(chessBoard, playerChess);
                  } else if (playerChessId == opponentChessId - 10) {
                    chessBoard[this.state.selectedPostion] = 0;
                    chessBoard[currentPosition] = 0;
                    playerChess[0] = playerChess[0].filter(
                      (item) => item !== playerChessId
                    );
                    playerChess[1] = playerChess[1].filter(
                      (item) => item !== opponentChessId
                    );
                    this.moveUpdate(chessBoard, playerChess);
                  } else {
                    chessBoard[this.state.selectedPostion] = 0;
                    // chessBoard[currentPosition]=playerChessId;
                    playerChess[0] = playerChess[0].filter(
                      (item) => item !== playerChessId
                    );
                    this.moveUpdate(chessBoard, playerChess);
                  }
                }
              } else {
                if (playerChessId == 11 && opponentChessId == 5) {
                  chessBoard[this.state.selectedPostion] = 0;
                  chessBoard[currentPosition] = playerChessId;

                  playerChess[0] = playerChess[0].filter(
                    (item) => item !== opponentChessId
                  );

                  this.moveUpdate(chessBoard, playerChess);
                } else if (playerChessId == 15 && opponentChessId == 1) {
                  chessBoard[this.state.selectedPostion] = 0;
                  chessBoard[currentPosition] = opponentChessId;

                  playerChess[1] = playerChess[1].filter(
                    (item) => item !== playerChessId
                  );

                  this.moveUpdate(chessBoard, playerChess);
                } else {
                  if (playerChessId - 10 > opponentChessId) {
                    chessBoard[this.state.selectedPostion] = 0;
                    chessBoard[currentPosition] = playerChessId;
                    playerChess[0] = playerChess[0].filter(
                      (item) => item !== opponentChessId
                    );
                    this.moveUpdate(chessBoard, playerChess);
                  } else if (playerChessId - 10 == opponentChessId) {
                    chessBoard[this.state.selectedPostion] = 0;
                    chessBoard[currentPosition] = 0;

                    playerChess[0] = playerChess[0].filter(
                      (item) => item !== opponentChessId
                    );
                    playerChess[1] = playerChess[1].filter(
                      (item) => item !== playerChessId
                    );
                    this.moveUpdate(chessBoard, playerChess);
                  } else {
                    chessBoard[this.state.selectedPostion] = 0;
                    // chessBoard[currentPosition]=playerChessId;
                    playerChess[1] = playerChess[1].filter(
                      (item) => item !== playerChessId
                    );
                    this.moveUpdate(chessBoard, playerChess);
                  }
                }
              }

              this.moveEvent(
                playerChessId,
                this.state.selectedPostion,
                currentPosition
              );
            }
            this.checkWinner(currentPosition);
          } else {
            //选择了其他棋子
            if (
              this.state.playerChess[this.state.playerId].includes(
                this.state.chessBoard[currentPosition]
              )
            ) {
              var movePositions = this.getMovePostions(currentPosition);
              this.setState({
                selectedPostion: currentPosition,
                movePositions: movePositions,
                isSelect: true,
              });
            }
          }
        }
      } else {
        //没有选中棋子
        //判断这个棋子是否是当前player

        console.log(
          " this.state.playerChess[this.state.playerId]",
          this.state.playerChess
        );

        if (
          this.state.playerChess[this.state.playerId].includes(
            this.state.chessBoard[currentPosition]
          )
        ) {
          console.log(this.state.playerId);

          var movePositions = this.getMovePostions(currentPosition);

          this.setState({
            selectedPostion: currentPosition,
            movePositions: movePositions,
            isSelect: true,
            isUpdate: false,
          });
        }
      }
    }
  }

  moveUpdate(chessBoard, playerChess) {
    this.setState({
      chessBoard: chessBoard,
      playerChess: playerChess,
      isSelect: false,
      moveAble: 100,
      selectedPostion: 100,
      movePositions: [],
    });
  }

  async moveEvent(chessId, currentPosition, nextPosition) {
    var playerId = parseInt(this.state.playerId);
    var oppoentId = playerId == 1 ? 0 : 1;

    const response = await toast.promise(
      async () => {
        var result = await this.state.chessBurn.chessMove(
          this.state.currentAddress,
          this.state.roomId,
          playerId,
          oppoentId,
          chessId,
          currentPosition,
          nextPosition
        );
        if (result.status) {
          await this.getChessData(
            this.state.chessBurn,
            this.state.roomId,
            this.state.currentAddress
          );
          this.setState({
            isUpdate: true,
          });
        }
      },
      {
        pending: { render: "moving", position: "top-center" },
        success: "Success",
        error: "Fail",
      }
    );
  }

  moveHover(hoverPosition) {
    if (this.state.movePositions.includes(hoverPosition)) {
      this.setState({
        moveAble: hoverPosition,
      });
    }
  }

  checkWinner(currentPosition) {
    var playerChess = this.state.playerChess;

    if (playerChess[0].length == 0 || playerChess[1].length == 0) {
      if (playerChess[0].length == 0 && playerChess[1].length == 0) {
        // alert("drawn");
      } else if (playerChess[0].length > 0 && playerChess[1].length == 0) {
        // alert("Player 1 win");
      } else if (playerChess[0].length == 0 && playerChess[1].length > 0) {
        // alert("Player 2 win");
      }
    } else {
      if (this.state.playerId == 0 && currentPosition == 27) {
        // alert("player 1 win");
      } else if (this.state.playerId == 1 && currentPosition == 2) {
        // alert("player 2 win");
      }
    }
  }

  getMovePostions(currentPosition) {
    var movePositions = [];
    if (currentPosition == 0) {
      movePositions = [1, 5];
    } else if (currentPosition == 4) {
      movePositions = [3, 9];
    } else if (currentPosition == 30) {
      movePositions = [25, 31];
    } else if (currentPosition == 34) {
      movePositions = [29, 33];
    } else if (currentPosition >= 1 && currentPosition <= 3) {
      movePositions = [
        currentPosition - 1,
        currentPosition + 1,
        currentPosition + 5,
      ];
    } else if (currentPosition >= 31 && currentPosition <= 33) {
      movePositions = [
        currentPosition - 1,
        currentPosition + 1,
        currentPosition - 5,
      ];
    } else if ([5, 10, 15, 20, 25].includes(currentPosition)) {
      movePositions = [
        currentPosition - 5,
        currentPosition + 1,
        currentPosition + 5,
      ];
    } else if ([9, 14, 19, 24, 29].includes(currentPosition)) {
      movePositions = [
        currentPosition - 5,
        currentPosition - 1,
        currentPosition + 5,
      ];
    } else {
      movePositions = [
        currentPosition - 5,
        currentPosition - 1,
        currentPosition + 1,
        currentPosition + 5,
      ];
    }

    // for(var position of movePositions){

    //     if(this.state.currentPlayerChess.includes(this.state.chessBoard[position])){

    //             movePositions=movePositions.filter(item => item !== position);
    //

    //     }
    // }

    return movePositions;
  }

  changeUser() {
    var playerId = this.state.playerId == 1 ? 0 : 1;

    this.setState({
      playerId: playerId,
    });
  }

  getShortAddress(longAddress) {
    let end = longAddress.length;
    let addressShort =
      longAddress.substring(0, 5) + "..." + longAddress.substring(end - 4, end);
    return addressShort;
  }

  async joinRoom() {
    const response = await toast.promise(
      async () => {
        var result = await this.state.chessBurn.joinRoom(
          this.state.roomId,
          this.state.currentAddress
        );
        window.location.reload();
      },
      {
        pending: { render: "pending", position: "top-center" },
        success: "Success",
        error: "Fail",
      }
    );
  }

  async applyWin() {
    const response = await toast.promise(
      async () => {
        var result = await this.state.chessBurn.applyToWin(
          this.state.currentAddress,
          this.state.roomId,
          this.state.playerId
        );
      },
      {
        pending: { render: "pending", position: "top-center" },
        success: "Success",
        error: "Fail",
      }
    );
  }

  render() {
    return (
      <div className="ChessContainer">
        <ToastContainer></ToastContainer>
        <Modal
          className="TutorialModal"
          title="Tutorial"
          open={this.state.showTutorial}
          footer={null}
          maskClosable={true}
          onCancel={() => {
            this.setState({
              showTutorial: false,
            });
          }}
        >
          <Tutorial></Tutorial>
        </Modal>

        <div className="GameDetailLogo">
          <img src={Logo} width="100%" />
        </div>
        <div className="GameDetailBtns">
          <a href="/" className="GameDetailRoom">
            Rooms
          </a>

          <div
            className="GameDetailTutorial"
            onClick={() => {
              this.setState({
                showTutorial: true,
              });
            }}
          >
            How to Play
          </div>
        </div>
        <Wallet></Wallet>
        <div hidden={this.state.showBoard}>
          <div className="GameDetail">
            {/* <div>Current User:{this.state.playerId}</div>
        <div>Round:{this.state.round}</div>
        <div>PlayerCount:{this.state.roomPlayerCount}</div>
        <div>isJoin:{this.state.isJoin ? "yes" : "no"}</div> */}

            <div className="ChessBoardContainer">
              <ul className="ChessBoardContainerLeft">
                <li>1</li>
                <li>2</li>
                <li>3</li>
                <li>4</li>
                <li>5</li>
                <li>6</li>
                <li>7</li>
              </ul>
              <div className="ChessBoardContainerRight">
                <ul className="ChessBoardContainerRightPosition">
                  <li>A</li>
                  <li>B</li>
                  <li>C</li>
                  <li>D</li>
                  <li>E</li>
                </ul>
                <ul className="ChessBoard">
                  {this.state.chessBoard.map((item, index) => (
                    <li
                      key={index}
                      onClick={this.selectChess.bind(this, index)}
                      onMouseOver={this.moveHover.bind(this, index)}
                      className={
                        this.state.selectedPostion == index
                          ? "selectedPostion"
                          : "normalPostion"
                      }
                    >
                      <div
                        className={
                          index == this.state.castle[0]
                            ? "Player1CastleBg"
                            : index == this.state.castle[1]
                            ? "Player2CastleBg"
                            : ""
                        }
                      >
                        <div
                          className={
                            index == this.state.moveAble ? "moveAbleBg" : ""
                          }
                        >
                          <img src={ChessImages.get(item)} width="100%" />
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="GameInfo">
              <div className="Status">
                <div className="StatusTitle">Room</div>

                <CopyToClipboard
                  onCopy={() => {
                    message.success("Copied", 1.5);
                  }}
                  text={"https://www.chainchess.xyz/room/" + this.state.roomId}
                >
                  <div className="CopyRoomId">
                    <div>#{this.state.roomId}</div>
                    <div className="CopyRoomIdButton">Copy Room Link</div>
                  </div>
                </CopyToClipboard>
              </div>
              <div className="Status">
                <div className="StatusTitle">Status</div>

                {this.state.gameState == 0 ? (
                  <div className="StatusValue0">
                    Waiting for player2 to join
                  </div>
                ) : this.state.gameState == 1 ? (
                  <div className="StatusValue1">Game in progress</div>
                ) : (
                  <div className="StatusValue2">Game over</div>
                )}
              </div>
              <div className="Players">
                <div className="PlayersTitle">Players</div>
                <div className="PlayersValue">
                  <div className="Player1Color"></div>
                  <div className="PlayerName">Player1</div>
                  <div className="PlayerAddress">
                    {this.state.players[0] != ""
                      ? this.getShortAddress(this.state.players[0])
                      : ""}
                  </div>
                </div>
                <div className="PlayersValue">
                  <div className="Player2Color"></div>
                  <div className="PlayerName">Player2</div>

                  {this.state.players[1] !=
                  "0x0000000000000000000000000000000000000000" ? (
                    <div className="PlayerAddress">
                      {this.getShortAddress(this.state.players[1])}
                    </div>
                  ) : (
                    <div className="NoPlayer2">
                      {!this.state.isJoin ? (
                        <div
                          className="Player2Join"
                          onClick={this.joinRoom.bind(this)}
                        >
                          Join
                        </div>
                      ) : (
                        <div></div>
                      )}
                      <a
                        className="InvitePlayer"
                        target="_blank"
                        href={
                          "https://twitter.com/intent/tweet?text=I'm playing @chainchess_xyz and invite you to join" +
                          " https://www.chainchess.xyz/room/" +
                          this.state.roomId
                        }
                      >
                        Invite
                      </a>
                    </div>
                  )}
                </div>
              </div>
              {this.state.gameState == 1 ? (
                <div className="Round">
                  <div className="RoundTitle">Round</div>
                  {this.state.round == 0 ? (
                    <div className="RoundValue">
                      <div className="Player1Color"></div>
                      <div className="PlayerName">Player1</div>
                    </div>
                  ) : (
                    <div className="RoundValue">
                      <div className="Player2Color"></div>
                      <div className="PlayerName">Player2</div>
                    </div>
                  )}
                </div>
              ) : (
                <div></div>
              )}

              {this.state.gameState == 2 ? (
                <div className="Winner">
                  <div className="WinnerTitle">Winner</div>

                  {this.state.winner == 0 ? (
                    <div className="WinnerPlayer">
                      <div className="Player1Color"></div>
                      <div className="PlayerName">Player1</div>
                      <div className="WinnerIcon">
                        <img src={WinnerIcon} width={32} />
                      </div>
                    </div>
                  ) : (
                    <div className="WinnerPlayer">
                      <div className="Player2Color"></div>
                      <div className="PlayerName">Player2</div>
                      <div className="WinnerIcon">
                        <img src={WinnerIcon} width={32} />
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div className=""></div>
              )}

              {this.state.showApplyWin ? (
                <div className="ApplyWin">
                  <div
                    onClick={this.applyWin.bind(this)}
                    className="ApplyWinButton"
                  >
                    Apply Win
                  </div>
                  <div className="ApplyWinText">
                    When your opponent does nothing after 3600 seconds, you can
                    apply for victory
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </div>
          {/* <div className="GameActivity">
            <div className="GameActivityTitle">Activity</div>
            <ul className="GameActivityList">
              <li>
                <div className="GameActivityPlayer">Player1</div>
                <div className="GameActivityOperation">
                  Move the King from A,2 to A,4
                </div>
                <div className="GameActivityDate">12:00:11 12/23</div>
              </li>
            </ul>
          </div> */}
        </div>
      </div>
    );
  }
}
export default withRouter(Chess);
