import { useEffect } from "react";
import attack from "../../assets/tutorial/attack.png";
import move from "../../assets/tutorial/move.png";
import king from "../../assets/player1/king.png";
import knight from "../../assets/player1/knight.png";
import magician from "../../assets/player1/magician.png";
import guard from "../../assets/player1/guard.png";
import assassin from "../../assets/player1/assassin.png";
import wingame from "../../assets/tutorial/wingame.png";
import "./Tutorial.css";
function Tutorial() {
  useEffect(() => {});

  return (
    <div className="Tutorial">
      <h3>Introduction to chess pieces</h3>
      <p>
        Each round of the game requires two players to participate. Each player
        will get a different color chess piece. Chain Chess has five roles:
      </p>

      <ul className="tutorial_hereos">
        <li>
          <img src={king} width={32}></img>
          <p>King</p>
        </li>
        <li>
          <img src={knight} width={32}></img>
          <p>Warrior</p>
        </li>
        <li>
          <img src={magician} width={32}></img>
          <p>Magician</p>
        </li>
        <li>
          <img src={guard} width={32}></img>
          <p>Guard</p>
        </li>
        <li>
          <img src={assassin} width={32}></img>
          <p>Ninja</p>
        </li>
      </ul>
      <h3>Killing rules</h3>
      <p>
        When a piece is killed, it disappears from the chessboard. The same
        chess pieces in different camps can also kill each other. For example,
        if the king of player1 kills the king of player2, then the two pieces
        will disappear together.
        <br /> <br />
        King: Warrior, Magician, Dragon
        <br />
        Warrior: Magician, Dragon, Ninja
        <br />
        Magician: Dragon, Ninja
        <br />
        Dragon: Ninja
        <br />
        Ninja: King
      </p>

      <img src={attack} width="100%" />
      <h3>How to move</h3>
      <p>
        The user can move a chess piece from his own camp by one square in each
        game, and the chess piece can only move up, down, left, and right.
      </p>
      <br />
      <img src={move} width="50%" />
      <h3>How to win</h3>
      <p>
        1. Kill all the chess pieces of the opponent's camp, and there is at
        least one chess piece of your own camp on the chessboard.
      </p>
      <p>
        2. Any of your own chess pieces enters the opponent's castle.
        </p>
        <img src={wingame} width="80%"/>
        <p>
          3. If the pieces of both sides kill each other and disappear, it is a
          draw.
        </p>
  
    </div>
  );
}

export default Tutorial;
