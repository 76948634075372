class TestSystem {
  constructor(web3, abi, address, caller) {
    this.web3 = web3;
    this.caller = caller;
    this.contract = new this.web3.eth.Contract(abi, address);
  }

  async getBytes() {
    const callResult = await this.contract.methods.getBytes().call();
    return callResult;
  }

  async getInfo() {
    const callResult = await this.contract.methods.getInfo().call();
    return callResult;
  }

  async getNum(_num) {
    const callResult = await this.contract.methods.getNum(_num).call();
    return callResult;
  }

  async getNums() {
    const callResult = await this.contract.methods.getNums().call();
    return callResult;
  }

  async getState() {
    const callResult = await this.contract.methods.getState().call();
    return callResult;
  }

  async getString(_str) {
    const callResult = await this.contract.methods.getString(_str).call();
    return callResult;
  }

  async multiParams(_num, _num2, _num3) {
    const callResult = await this.contract.methods
      .multiParams(_num, _num2, _num3)
      .call();
    return callResult;
  }

  async payAmount(_value) {
    const gasPrice = await this.web3.eth.getGasPrice();
    const gas = await this.contract.methods
      .payAmount()
      .estimateGas({ from: this.caller, value: _value });
    const sendResult = await this.contract.methods
      .payAmount()
      .send({ from: this.caller, value: _value, gasPrice: gasPrice, gas: gas });
    return sendResult;
  }

  async sendNoParams() {
    const gasPrice = await this.web3.eth.getGasPrice();
    const gas = await this.contract.methods
      .sendNoParams()
      .estimateGas({ from: this.caller });
    const sendResult = await this.contract.methods
      .sendNoParams()
      .send({ from: this.caller, gasPrice: gasPrice, gas: gas });
    return sendResult;
  }

  async setBytes(_bytesTest) {
    const gasPrice = await this.web3.eth.getGasPrice();
    const gas = await this.contract.methods
      .setBytes(_bytesTest)
      .estimateGas({ from: this.caller });
    const sendResult = await this.contract.methods
      .setBytes(_bytesTest)
      .send({ from: this.caller, gasPrice: gasPrice, gas: gas });
    return sendResult;
  }

  async setInfo(_name, _age) {
    const gasPrice = await this.web3.eth.getGasPrice();
    const gas = await this.contract.methods
      .setInfo(_name, _age)
      .estimateGas({ from: this.caller });
    const sendResult = await this.contract.methods
      .setInfo(_name, _age)
      .send({ from: this.caller, gasPrice: gasPrice, gas: gas });
    return sendResult;
  }

  async setNums(_nums) {
    const gasPrice = await this.web3.eth.getGasPrice();
    console.log( this.caller)
    const gas = await this.contract.methods
      .setNums(_nums)
      .estimateGas({ from: this.caller });
    
    const sendResult = await this.contract.methods
      .setNums(_nums)
      .send({ from: this.caller, gasPrice: gasPrice, gas: gas });
    return sendResult;
  }

  async setState(_state) {
    const gasPrice = await this.web3.eth.getGasPrice();
    const gas = await this.contract.methods
      .setState(_state)
      .estimateGas({ from: this.caller });
    const sendResult = await this.contract.methods
      .setState(_state)
      .send({ from: this.caller, gasPrice: gasPrice, gas: gas });
    return sendResult;
  }
}
export default TestSystem;
