import React, { useState, useEffect } from "react";
import EthCrypto from "eth-crypto";
import Web3 from "web3";
import MoveABI from "./move.json";
import TestSystem from "./TestSystem";
import TestSystemABI from "./TestSystemABI.json";
function BurnerWallet(props) {
  const generateBurnWallet = () => {
    const burnerWallet = EthCrypto.createIdentity();
    console.log("burnerWallet",burnerWallet);
    localStorage.setItem("burnerWallet", JSON.stringify(burnerWallet));
    writeToFile(burnerWallet)
  };

  const  writeToFile = (burnerWallet) => {
    const fileData = JSON.stringify(burnerWallet);
    const blob = new Blob([fileData], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.download = 'BurnWallet.json';
    link.href = url;
    link.click();
  };


  const move = async () => {
 
    const web3 = new Web3("http://127.0.0.1:7545");
    var contract = new web3.eth.Contract(
      MoveABI,
      "0x6DA6d3698Ba0189C94D1B7e470a2C61F47660002"
    );
    const burnerWallet = JSON.parse(localStorage.getItem("burnerWallet"));
    web3.eth.accounts.wallet.add(burnerWallet.privateKey);
    const randomPosition = Math.floor(Math.random() * 101);
    const gasPrice = await web3.eth.getGasPrice();
    const gas = await contract.methods
      .move(randomPosition, randomPosition)
      .estimateGas({ from: burnerWallet.address });
    const transactionResult = await contract.methods
      .move(randomPosition, randomPosition)
      .send({ from: burnerWallet.address, gasPrice: gasPrice, gas: gas });
    console.log("transactionResult", transactionResult);
    const position = await contract.methods
      .getPosition(burnerWallet.address)
      .call();
    console.log("position", position);
  };

  const getFunc=async ()=>{
    const web3 = new Web3("http://127.0.0.1:7545");
    const burnerWallet = JSON.parse(localStorage.getItem("burnerWallet"));
    web3.eth.accounts.wallet.add(burnerWallet.privateKey);
    var testFunc=new TestSystem(web3,TestSystemABI,"0x967e4D9603A0CD7CaF523281dD41DE2BdAf9279c",burnerWallet.address);
    var nums=await testFunc.getNum(1);
    console.log("nums",nums)
  }

  const sendFunc=async ()=>{
    const web3 = new Web3("http://127.0.0.1:7545");
    const burnerWallet = JSON.parse(localStorage.getItem("burnerWallet"));
    web3.eth.accounts.wallet.add(burnerWallet.privateKey);
    var testFunc=new TestSystem(web3,TestSystemABI,"0x967e4D9603A0CD7CaF523281dD41DE2BdAf9279c",burnerWallet.address);
    var nums=await testFunc.setNums([1,2,3]);
    console.log("nums",nums)
  }

  const payFunc=async ()=>{
    const web3 = new Web3("http://127.0.0.1:7545");
    const burnerWallet = JSON.parse(localStorage.getItem("burnerWallet"));
    web3.eth.accounts.wallet.add(burnerWallet.privateKey);
    var testFunc=new TestSystem(web3,TestSystemABI,"0x967e4D9603A0CD7CaF523281dD41DE2BdAf9279c",burnerWallet.address);
    var nums=await testFunc.payAmount("1000000000000000000");
    console.log("nums",nums)
  }

  return (
    <div>
      <button onClick={generateBurnWallet}>Generate Burner Wallet</button>
      <button onClick={move}>Move</button>
      <button onClick={getFunc}>Get Func</button>
      <button onClick={sendFunc}>Send Func</button>
      <button onClick={payFunc}>Pay Func</button>
    </div>
  );
}
export default BurnerWallet;
